import React, { useRef } from "react";
import HTMLFlipBook from "react-pageflip";
import biglogo from "../assets/images/biglogo.jpg";

const Book = () => {
  const book = useRef();

  return (
    <>
      <div className="container flex flex-col lg:flex-row px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-col sm:flex-col gap-2">
        <div className="lg:w-1/2 w-full md:w-full sm:w-full">
          <HTMLFlipBook
            width={300}
            height={450}
            ref={book}
            className="shadow-md"
          >
            <div className="demoPage flex justify-center flex-col text-center border border-1 bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">No Jeets Allowed</p>
            </div>
            <div className="demoPage flex justify-center flex-col text-center border border-1  bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">Based Dev Required</p>
            </div>
            <div className="demoPage flex justify-center flex-col text-center border border-1  bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">Community Required</p>
            </div>
            <div className="demoPage flex justify-center flex-col text-center border border-1  bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">Like The Token</p>
            </div>
            <div className="demoPage flex justify-center flex-col text-center border border-1  bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">
                Eat The Fucking Dip
              </p>
            </div>
            <div className="demoPage flex justify-center flex-col text-center border border-1  bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">
                Keep Fucking Buying
              </p>
            </div>
            <div className="demoPage flex justify-center flex-col text-center border border-1  bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">Hodl</p>
            </div>
            <div className="demoPage flex justify-center flex-col text-center border border-1  bg-red-800">
              <img src={biglogo} alt="biglogo" />
              <p className="text-xl text-white text-bold">
                Read Page One Again
              </p>
            </div>
          </HTMLFlipBook>
        </div>
        <div className="lg:w-1/2 w-full md:w-full sm:w-full">
          <div className="flex flex-col items-center text-center w-full">
            <h1 className="text-3xl text-bold text-black">
              Did you read that book?
            </h1>
            <p className="text-center">
              Careful reading requires patience, attention, and an openness to
              explore the depths of a narrative. It's about delving beyond the
              surface and uncovering layers of meaning hidden within the text.
              As you embark on a new literary adventure, take the time to savor
              each word, allowing them to paint vivid images in your mind's eye.
              Engage with the characters, empathize with their struggles, and
              celebrate their triumphs.
            </p>
            <p>
              But careful reading is more than just absorbing a story; it's
              about engaging in a dialogue with the author. Question the motives
              of characters, ponder the symbolism woven into the narrative, and
              contemplate the themes that resonate with your own experiences.
            </p>
            <p>
              So, the next time you pick up a book, remember to read carefully.
              For within its pages lies a world waiting to be discovered, and
              it's up to you to explore it with intention and curiosity.
            </p>
          </div>
          <div className="p-10 flex flex-row gap-5 justify-center items-center">
            <button
              onClick={() => book.current.pageFlip().flipPrev()}
              className="p-4 bg-red-500 rounded-xl"
            >
              Prev page
            </button>
            <button
              onClick={() => book.current.pageFlip().flipNext()}
              className="p-4 bg-red-500 rounded-xl"
            >
              Next page
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Book;
