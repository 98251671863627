import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <section id="cta" className="bg-red-500">
      {/* Flex Container */}
      <div className="container flex flex-col items-center justify-center px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0">
        <iframe
          id="dextools-widget"
          title="DEXTools Trading Chart"
          width="100%"
          height="400"
          src="https://www.dextools.io/widget-chart/en/solana/pe-light/E3j1jm1XGDtvfcRL2D2J6M3AtcGxdYDLmY5kMsW3d2Dt?theme=dark&chartType=1&chartResolution=30&drawingToolbars=true"
          className="responsive-iframe"
        ></iframe>
      </div>
    </section>
  );
};

export default CallToAction;
