import { Link } from "react-router-dom";

import illustrationIntro from "../assets/images/biglogo.jpg";
import raydium from "../assets/images/raydium.svg";
import telegram from "../assets/images/telegram.svg";
import twitter from "../assets/images/twitter.svg";
import dextools from "../assets/images/dextools.svg";
import dexscreener from "../assets/images/dex-screener-seeklogo.png";
const Hero = () => {
  return (
    <section id="hero">
      {/* Flex Container */}
      <div className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row">
        {/* Left Item */}
        <div className="flex flex-col mb-32 space-y-12 md:w-1/2">
          <h1 className="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
            $BOGME
          </h1>
          <p className="max-w-md text-3xl text-bold text-center md:text-left">
            Book of Game Stop
          </p>
          <div className="flex gap-5">
            <a href="https://t.me/BookofGMESol" target="_blank">
              <img
                src={telegram}
                alt=""
                width={40}
                height={40}
                className="rounded-md"
              />{" "}
            </a>
            <a href="https://twitter.com/BookofGME_" target="_blank">
              <img
                src={twitter}
                alt=""
                width={40}
                height={40}
                className="rounded-md"
              />{" "}
            </a>
            <a href="https://www.dextools.io/app/en/solana/pair-explorer/E3j1jm1XGDtvfcRL2D2J6M3AtcGxdYDLmY5kMsW3d2Dt?t=1715694392326">
              <img
                src={dextools}
                alt=""
                width={40}
                height={40}
                className="rounded-md"
              />{" "}
            </a>
            <a
              href="https://dexscreener.com/solana/e3j1jm1xgdtvfcrl2d2j6m3atcgxdydlmy5kmsw3d2dt"
              target="_blank"
            >
              <img
                src={dexscreener}
                alt=""
                width={40}
                height={40}
                className="rounded-md"
              />{" "}
            </a>
          </div>
          <div className="flex justify-center md:justify-start">
            <a
              href="https://dexscreener.com/solana/e3j1jm1xgdtvfcrl2d2j6m3atcgxdydlmy5kmsw3d2dt"
              target="_blank"
              className="p-3 px-6 pt-2 text-white bg-red-700 rounded-full baseline hover:bg-red-500"
            >
              CHART
            </a>
          </div>
        </div>
        {/* Image */}
        <div className="md:w-1/2">
          <img src={illustrationIntro} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
