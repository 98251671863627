import { useState } from "react";
import { Link } from "react-router-dom";

import companyLogo from "../assets/images/biglogo.jpg";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="relative container mx-auto p-6">
      {/* Flex Container */}
      <div className="flex items-center justify-between">
        {/* Logo */}
        <div className="pt-2">
          <img src={companyLogo} alt="" width={100} />
        </div>
        <a
          href="https://dexscreener.com/solana/e3j1jm1xgdtvfcrl2d2j6m3atcgxdydlmy5kmsw3d2dt"
          target="_blank"
          className="p-3 px-6 pt-2 text-white bg-red-700 rounded-full baseline hover:bg-red-700 md:block"
        >
          Chart
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
