import { Link } from "react-router-dom";

import { FaFireAlt, FaCoins , FaMoneyBillWave } from "react-icons/fa";

const Testimonial = () => {
  return (
    <section id="testimonials ">
      <div className="flex gap-5 mx-auto container w-full p-10">
        {/* First  */}
        <div className="flex flex-col bg-black rounded-lg shadow-md shadow-current w-1/2 p-2">
          <div className="flex justify-center">
            <FaCoins className="text-4xl text-red-500 " />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-bold text-center text-white">
              Total Supply
            </h2>
            <p className="text-center text-white">1 BILLION</p>
          </div>
        </div>
        {/* Second  */}

        <div className="flex flex-col bg-black rounded-lg shadow-md shadow-current w-1/2 p-2">
          <div className="flex justify-center">
            <FaMoneyBillWave className="text-4xl text-red-500 " />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-bold text-center text-white">
              ΤΑΧ
            </h2>
            <p className="text-center text-white">0% ΤΑΧ</p>
          </div>
        </div>
        {/* thrid  */}
        <div className="flex flex-col bg-black rounded-lg shadow-md shadow-current w-1/2 p-2">
          <div className="flex justify-center items-center">
            <FaFireAlt className="text-4xl text-red-500 " />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-bold text-center text-white">
            Liquidity
            </h2>
            <p className="text-center text-white">Burnt</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
